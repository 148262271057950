<template>
  <div id="register-page">
    <FirstTemplateHome>
      <div v-if="statusSend === 0">
        <el-tabs
          type="card"
          class="select-tab"
          @tab-click="handleClick"
          v-model="activeNameTab"
        >
          <el-tab-pane label="Admin" name="admin"></el-tab-pane>
          <el-tab-pane label="Auditor" name="auditor"></el-tab-pane>
        </el-tabs>
        <div class="content">
          <el-form
            @submit.native.prevent="submitRegis"
            label-position="top"
            :model="form"
            ref="ruleFormRegis"
            :rules="rules"
          >
            <el-divider content-position="center">สมัครสมาชิก</el-divider>
            <FormRegis :form="form" :type="activeNameTab" />
            <el-button
              type="warning"
              native-type="submit"
              round
              class="mg-t-3 mg-y-4 w-100"
              >สมัครสมาชิก</el-button
            >
            <div class="text-center">
              <span class="mg-r-3">มีบัญชีอยู่แล้ว? </span>
              <span
                class="cr-pointer color-pending text-decoration-underline"
                @click="$router.push(`/login`)"
                >เข้าสู่ระบบ
              </span>
            </div>
          </el-form>
        </div>
      </div>
      <div v-else-if="statusSend === 1" class="text-center">
        <div>
          <i class="fas fa-exclamation-circle notification color-pending"></i>
          <h1 class="font-weight-400">รอการอนุมัติจากเจ้าหน้าที่</h1>
          <h3>กรุณาตรวจสอบอีเมลของท่าน</h3>
        </div>
      </div>
    </FirstTemplateHome>
  </div>
</template>
<script>
import "@/mixin/FirstTemplateHome";
import FormRegis from "@/components/register/FormRegis";
import { HTTP } from "@/service/axios";

export default {
  components: {
    FormRegis,
  },
  mounted() {
    window.localStorage.removeItem("UIC");
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่านอีกครั้ง"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      activeNameTab: "admin",
      form: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        newPassword: "",
        password: "",
      },
      statusSend: 0,
      rules: {
        name: [
          {
            required: true,
            message: "กรุณากรอกชื่อ",
            trigger: ["blur", "change"],
          },
        ],
        surname: [
          {
            required: true,
            message: "กรุณากรอกนามสกุล",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
          },
          {
            type: "email",
            message: "กรุณากรอกอีเมลให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่าน",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
          { validator: validatePass, trigger: ["blur", "change"] },
        ],
        password: [
          {
            required: true,
            message: "กรุณากรอกยืนยันรหัสผ่าน",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
          { validator: validatePass2, trigger: ["blur", "change"] },
        ],
        phone: [
          {
            required: true,
            message: "กรุณากรอกเบอร์โทรศัพท์",
            trigger: ["blur", "change"],
          },
          {
            min: 10,
            message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitRegis() {
      this.$refs["ruleFormRegis"].validate((valid) => {
        if (valid) {
          delete this.form.newPassword;
          let obj = {
            ...this.form,
            role: this.activeNameTab == "admin" ? 60 : 50,
          };
          HTTP.post(`user/create/admin`, obj)
            .then((res) => {
              if (res.data.success) {
                this.statusSend = 1;
              } else {
                if (res.data.data == "Email is already exists") {
                  this.$message({
                    message: "อีเมลนี้มีผู้ใช้งานแล้ว",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 422) {
                this.$message({
                  message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }
            });
        }
      });
    },
    handleClick(tab) {
      this.loading = true;
      this.activeNameTab = tab.name;
    },
  },
};
</script>
