<template>
  <div>
    <el-form-item label="ชื่อ" prop="name">
      <el-input v-model="form.name" clearable placeholder="กรุณากรอกชื่อ">
      </el-input>
    </el-form-item>
    <el-form-item label="นามสกุล" prop="surname">
      <el-input v-model="form.surname" clearable placeholder="กรุณากรอกนามสกุล">
      </el-input>
    </el-form-item>
    <el-form-item label="เบอร์โทรศัพท์" prop="phone" class="fix-label">
      <el-input
        id="mobilephone"
        v-model="form.phone"
        maxlength="10"
        @keypress.native="isNumber($event)"
        placeholder="กรุณากรอกเบอร์โทรศัพท์"
      ></el-input>
    </el-form-item>
    <el-form-item label="อีเมล" prop="email">
      <el-input v-model="form.email" clearable placeholder="กรุณากรอกอีเมล">
      </el-input>
    </el-form-item>
    <el-form-item label="สร้างรหัสผ่าน" prop="newPassword">
      <el-input
        v-model="form.newPassword"
        show-password
        placeholder="กรุณากรอกรหัสผ่าน"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="ยืนยันรหัสผ่าน" prop="password">
      <el-input
        v-model="form.password"
        show-password
        placeholder="กรุณากรอกยืนยันรหัสผ่าน"
      >
      </el-input>
    </el-form-item>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
